import useCampaign from '@/views/campaign/useCampaign'
import ProductModal from '@/modal/productModal'
import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import util from '@/global/util'
import store from '@/store'
import http from '@/global/http'
import constant from '@/global/const'

export default function useCampaignDetail() {
  const {
    actions,
    productMenuType,
    productMenuTypeVI,
    //
    fetchProduct,
    fetchSuggestedListCustomer,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
    renderProductAddress,
    renderProductStructure,
  } = useCampaign()
  const today = new Date()
  const closeDealSignAtMonth = ref(today.getMonth() + 1)
  const closeDealSignAtYear = ref(today.getFullYear())
  const closeDealExpireMonth = ref('')
  const closeDealExpireYear = ref('')
  const totalProducts = ref(0)
  const campaignReport = ref([])
  const campaignReportToday = ref([])
  const campainName = ref('')
  const currentModalCampaignData = ref({})
  const campaignConfirm = ref([])
  const suggestedList = ref([])
  const showImage160 = ref([])
  const product = ProductModal()
  const supporterAdmin = ref([])
  const tableCampaignTodayDetail = [
    {
      label: 'Tên nhân viên',
      key: 'name',
    },
    {
      label: 'Tổng đã gọi',
      key: 'called',
    },
    {
      label: 'Còn cho thuê',
      key: 'counts.available',
    },
    {
      label: 'Còn cho thuê - gửi căn khác',
      key: 'counts.available_send_another_product',
    },
    {
      label: 'Đã cho thuê',
      key: 'counts.unavailable',
    },
    {
      label: 'Đã cho thuê - gửi căn khác',
      key: 'counts.unavailable_send_another_product',
    },
    {
      label: 'Không bắt máy',
      key: 'counts.dont_pick_up',
    },
    {
      label: 'Chủ củ - không phải chủ',
      key: 'counts.not_the_owner',
    },
    {
      label: 'Môi giới',
      key: 'counts.agency',
    },
    {
      label: 'Chưa check được',
      key: 'counts.cannot_checked',
    },
  ]
  const tableCampaignDetail = [
    {
      label: 'Tên nhân viên',
      key: 'name',
    },
    {
      label: 'Tổng đã xác nhận',
      key: 'called',
    },
    {
      label: 'Còn lại',
      key: 'new',
    },
    {
      label: 'Còn cho thuê',
      key: 'counts.available',
    },
    {
      label: 'Còn cho thuê - gửi căn khác',
      key: 'counts.available_send_another_product',
    },
    {
      label: 'Đã cho thuê',
      key: 'counts.unavailable',
    },
    {
      label: 'Đã cho thuê - gửi căn khác',
      key: 'counts.unavailable_send_another_product',
    },
    {
      label: 'Không bắt máy',
      key: 'counts.dont_pick_up',
    },
    {
      label: 'Chủ củ - không phải chủ',
      key: 'counts.not_the_owner',
    },
    {
      label: 'Môi giới',
      key: 'counts.agency',
    },
    {
      label: 'Chưa check được',
      key: 'counts.cannot_checked',
    },
  ]
  const tableCampaignConfirm = [
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tiêu đề',
      key: 'title',
      thClass: 'minw-250',
    },
    // {
    //   label: 'Người xác nhận',
    //   key: 'user.fullname',
    //   thClass: 'minw-250',
    // },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      formatter: (value, key, item) => renderProductStructure(item),
    },
    {
      label: 'Giá tổng',
      key: 'price',
    },
    {
      label: 'Tên chủ',
      key: 'ten-chu',
    },
    {
      label: 'Trạng thái hiện tại',
      key: 'confirm_status',
    },
    {
      label: 'Trạng thái',
      key: 'status',
    },
    {
      label: 'Kết quả',
      key: 'result',
      thClass: 'minw-250',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'text-right',
    },
  ]

  const routeParams = ref({})
  const totalSuggestedList = ref(0)
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const updateCampaignData = ref({
    status: '',
    result: '',
    isNotHaveInfoExpired: null,
    expireMonth: '',
    expireYear: '',
    expire_month: '',
  })
  const fitFilter = reactive({
    user_id: ref(null),
    status: ref(null),
  })

  const calculateTotalMonths = (months, years) => (Number(years) * 12) + Number(months)

  const users = computed(() => store.state.app.users)

  const fetchCampaignsReport = id => {
    const filterId = id || routeParams.value.id
    store.dispatch('campaign/fetchCampaignsReport', filterId)
      .then(resp => {
        campainName.value = resp.data.session_name || 'CHIẾN DỊCH'
        campaignReport.value = resp.data.data || []
      })
  }

  const fetchCampaignsReportToday = id => {
    const filterId = id || routeParams.value.id
    store.dispatch('campaign/fetchCampaignsReportToday', filterId)
      .then(resp => {
        campaignReportToday.value = resp.data.data || []
      })
  }

  const fetchCampaignsConfirm = q => {
    const buildQuery = q || http.buildQuery({
      session_id: routeParams.value.id,
      page: currentPage.value - 1,
      perpage: perPage.value,
      status: fitFilter.status,
      user_id: fitFilter.user_id,
    })
    store.dispatch('campaign/fetchCampaignsConfirm', buildQuery)
      .then(resp => {
        campaignConfirm.value = resp.data.data.result || []
        totalProducts.value = resp.data.data.total
      })
  }

  watch(fitFilter, () => {
    fetchCampaignsConfirm()
  })

  const fetchSuggestedList = (id, cb) => {
    store.dispatch('campaign/fetchCampaignsConfirm', http.buildQuery({
      session_id: routeParams.value.id,
      page: currentPage.value - 1,
      perpage: perPage.value,
    })).then(resp => {
      campaignConfirm.value = resp.data.data.result || []
      totalProducts.value = resp.data.data.total
    })
  }
  const handleUpdateCampaign = (data, cb) => {
    const dataUpdate = {
      status: data.status,
      expire_month: data.isNotHaveInfoExpired ? 0 : calculateTotalMonths(data.expireMonth, data.expireYear),
    }
    store.dispatch('campaign/updateCampaign', { ...dataUpdate, id: currentModalCampaignData.value.id }).then(resp => {
      currentModalCampaignData.value = resp.data.data
      fetchCampaignsReport()
      fetchCampaignsConfirm()
    })
  }

  watch([currentPage, perPage], () => {
    fetchCampaignsConfirm()
  })

  watch([closeDealExpireYear, closeDealExpireMonth, closeDealSignAtMonth, closeDealSignAtYear], () => {
    const d = new Date(`${closeDealSignAtYear.value || today.getFullYear()}/${closeDealSignAtMonth.value || (today.getMonth() + 1)}`)
    const ey = Number.parseInt(d.getFullYear(), 10) + (Number.parseInt(closeDealExpireYear.value, 10) || 0)
    const em = Number.parseInt(d.getMonth() + 1, 10) + (Number.parseInt(closeDealExpireMonth.value, 10) || 0)
    product.expire_contract_date = new Date(ey, em).toISOString()
  })
  watch(() => updateCampaignData.value.isNotHaveInfoExpired, value => {
    if (value) {
      updateCampaignData.value.expireMonth = ''
      updateCampaignData.value.expireYear = ''
    }
  })

  return {
    product,
    suggestedList,
    productMenuType,
    productMenuTypeVI,
    actions,
    tableCampaignDetail,
    tableCampaignConfirm,
    perPage,
    perPageOptions,
    users,
    routeParams,
    totalSuggestedList,
    currentPage,
    closeDealExpireYear,
    closeDealExpireMonth,
    totalProducts,
    closeDealSignAtMonth,
    closeDealSignAtYear,
    campaignReport,
    campaignReportToday,
    campaignConfirm,
    currentModalCampaignData,
    campainName,
    showImage160,
    updateCampaignData,
    supporterAdmin,
    fitFilter,
    tableCampaignTodayDetail,
    //
    fetchProduct,
    closeDealProduct,
    openDealProduct,
    handleUpdateCampaign,
    refreshProduct,
    fetchSuggestedList,
    fetchCampaignsConfirm,
    fetchCampaignsReport,
    fetchCampaignsReportToday,
  }
}
